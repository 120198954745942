<template>
  <div class="cards-filters-tmk">


    <div class="cards-filters-tmk-side" style="align-items: flex-start;align-content: flex-start;">
      <search-input
        v-if="isFilterVisible('search')"
        placeholder="Поиск по ФИО, личному номеру, должности, дирекции"
        style="width: 380px"
        v-model="filters.search"
        @change="filtersChanged"
      >
      </search-input>

      <user-unique-prop-values-select
        placeholder="Локация"
        v-model="filters.locations"
        prop="location"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>

      <user-unique-prop-values-select
        placeholder="Дирекция"
        v-model="filters.directions"
        prop="direction"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>

      <user-unique-prop-values-select
        placeholder="Цех"
        style="width: 100px"
        v-model="filters.workshops"
        prop="workshop"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>

      <user-select
        placeholder="ФИО НР"
        style="width: 300px"
        v-model="filters.direct_chief_user_id"
        :multiple="false"
        @selected="filtersChanged"
      ></user-select>

      <user-unique-prop-values-select
        v-model="filters.bands"
        placeholder="Бенд"
        style="width: 150px"
        prop="band"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>

      <cards-year-select
        v-model="filters.year"
        style="width:100px"
        :multiple="false"
        @change="filtersChanged"
      ></cards-year-select>

      <el-select
        v-model="availableCards"
        :multiple="false"
        clearable
        placeholder="Доступные карты"
      >
        <el-option label="Мои карты целей" value="my"></el-option>
        <el-option label="На моем шаге" value="my_turn"></el-option>
        <el-option label="Карты целей непосредственных подчиненных НР" value="immediate_subordinates_tree_direct"></el-option>
        <el-option label="Карты целей всех подчиненных НР" value="all_subordinates_tree_direct"></el-option>
        <el-option label="Карты целей непосредственных подчиненных ФР" value="immediate_subordinates_tree_functional"></el-option>
        <el-option label="Карты целей всех подчиненных ФР" value="all_subordinates_tree_functional"></el-option>
        <el-option label="Карты целей для Менеджера по персоналу (Самара)" value="all_subordinates_tree_hr_samara"></el-option>
        <el-option label="Карты целей для Менеджера по персоналу (Москва)" value="all_subordinates_tree_hr_moscow"></el-option>
      </el-select>

      <cards-status-select
        v-model="filters.statuses"
        placeholder="Статус карты"
        @change="filtersChanged"
      ></cards-status-select>

      <el-select
        v-model="filters.total_evaluation_visibility"
        clearable
        placeholder="Статус обратной связи"
      >
        <el-option label="Ознакомлен" :value="1"></el-option>
        <el-option label="Не ознакомлен" :value="0"></el-option>
      </el-select>

    </div>

  </div>
</template>

<script>

import SearchInput from "@/components/filters/SearchInput";
import CardsYearSelect from "@/components/filters/cards/CardsYearSelect";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";
import CardsPeriodSelect from "@/components/filters/cards/CardsPeriodSelect.vue";
import UserSelect from "@/components/filters/users/UserSelect.vue";
import CardsStatusSelect from "@/components/filters/cards/CardsStatusSelect.vue";
import UserUniquePropValuesSelect from "@/components/filters/users/UserUniquePropValuesSelect.vue";

export default {
  name: "cards-page-filters",
  components: {
    UserUniquePropValuesSelect,
    CardsStatusSelect,
    UserSelect,
    CardsPeriodSelect, CardsStageNegotiationSelect, CardsStageSelect, CardsYearSelect, SearchInput},

  props: {
    value: {},
    hidden: {type: Array},
  },

  computed: {},
  watch: {
    availableCards: function () {
      this.filters.my = this.availableCards === 'my' ? 1 : null;
      this.filters.my_turn = this.availableCards === 'my_turn' ? 1 : null;

      this.filters.subordinates = this.availableCards;

      this.filtersChanged();
    },
    'filters.total_evaluation_visibility': function () {
      if( this.filters.total_evaluation_visibility === '' ){
        this.filters.total_evaluation_visibility = null;
      }

      this.filtersChanged();
    },
  },
  data() {
    return {
      availableCards: [],
      filters: {
        search: null,
        locations: [],
        directions: [],
        workshops: [],
        direct_chief_user_id: null,
        bands: [],
        year: null,
        my: null,
        my_turn: null,
        subordinates: null,
        total_evaluation_visibility: null,
        statuses: null,
      },
    }
  },
  beforeMount() {
    this.filters.my = this.$route.query.my ? 1 : null;
  },
  mounted() {
    this.filters = {...this.filters, ...this.value};

    this.filtersChanged()
  },
  methods: {
    filtersChanged() {
      this.$emit('input', this.filters);
      this.$emit('change', this.filters);
    },

    isFilterVisible(filterName){
      if( this.hidden && this.hidden.includes(filterName) ){
        return false;
      }
      return true;
    },
  }
}
</script>


<style lang="scss">

.cards-filters-tmk {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;

  &-side {
    display: flex;
    gap: 1.5rem;
  }
}
</style>